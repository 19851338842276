/*
Template Name:Unikit - Admin & Dashboard Template
Author: Mannatthemes
File: footer Css
*/


.footer {
  bottom: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  left: 0;
  color: $gray-600;
  border-top: 1px solid $border;
}