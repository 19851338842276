// 
// _tables.scss
// 


.table {
  th{
    color: $gray-500;
    font-weight: 400;
    vertical-align: middle;
    border-color: $table-border-color;
  }
  td {
    font-weight: 500;
    vertical-align: middle;
    border-color: $table-border-color;
  }
  &.border-dashed{
    th {
      font-weight: 400;
      border-top: 1px dashed $table-border-color;      
    }
    td{
      border-top: 1px dashed $table-border-color;
    }
    thead {
      th {
        border-bottom: 1px dashed $table-border-color;
      }
    }
  }
  
  .thead-light {
    th{
      color: $gray-500;
      background-color: $gray-100;
      border-color: $table-border-color;
      border-bottom: none;
    }
  }
  &.table-dark {
    color: $gray-100;
    th{
      color: $gray-100;
    }
  }
  tbody tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }
  tbody{
    tr:hover{
      box-shadow: $card-shadow;
    }
  }
}
thead{
  >tr {
    th:first-child{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    th:last-child{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}


  
  /*== Table Responsive ==*/
  
  .table-rep-plugin {
    .btn-toolbar {
        display: block;
    }
    .btn-default {
        border: none;
        background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
        color: $white;
        margin-right: 5px;
        box-shadow: 0 7px 14px 0 rgba($primary, 0.5);
        transition: .3s ease-out;
        &:hover,
        &:focus {
            color: $white;
            box-shadow: 0 0 0 2px rgba($primary,.3);
        }
    }
    .btn-default.btn-primary:active,
    .btn-default.btn-primary:hover,
    .btn-default.btn-primary:focus {
        border: none !important;
        background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
        color: $white;
        box-shadow: 0 0 0 2px rgba($primary,.3);
    }
    tbody th {
        font-size: 14px;
        font-weight: normal;
    }

    table.focus-on tbody tr.focused th, 
    table.focus-on tbody tr.focused td {
      background-color: $primary;
      color: $white;
  }
}




/* Sticky Header */
.fixed-solution .sticky-table-header {
  top: 70px !important;
  background-color: $gray-100;
  border-top: 1px solid $border_2;
  border-bottom: 1px solid $border_2;
}

.table-responsive[data-pattern="priority-columns"]{
  border: 1px solid $border_2;
}

.attendance-table{
  .table thead tr th{
    padding: 5px;
  }
}

.dataTable-table > thead > tr > th{
  border: none;
}


.dataTable-selector,
.dataTable-input{
  color: $input-color;
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: 0.25rem;
}    

.dataTable-table {
  > tbody > tr > td, 
  > tbody > tr > th, 
  > tfoot > tr > td, 
  > tfoot > tr > th, 
  > thead > tr > td, 
  > thead > tr > th{
    padding:  $table-cell-padding-y $table-cell-padding-x;
  }
}
 
.dataTable-wrapper{
  &.no-footer {
    .dataTable-container {
      border-bottom: 1px dashed $border;
    }
  }
}