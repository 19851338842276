

// kanban board
.kanban-board{
  flex: 1;
  white-space: nowrap;
  overflow-x: scroll;
  display: flex;
  .kanban-col {
    user-select: none;
    flex: 1 0 auto;
    width: calc(100vw - 24px);
    max-height: 100%;
    padding-right: 12px;
    padding-bottom: 12px;
    outline: none !important;
    position: relative;
    .kanban-main-card {
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 12px;
      border-radius: 4px;
      padding: 20px;
      background: $light;
      .kanban-box-title{
        position: relative;
        display: block;
        .kanban-main-dropdown{
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      
    }
  }
}

@media (min-width: 576px){
  .kanban-board .kanban-col {
    max-width: 19.5rem;
  }
}


// project-task

.project-task-content{
  flex: 1;
  white-space: inherit;
  display: flex;
  overflow: auto;
  .project-task-content-col{
    user-select: none;
    flex: 1 0 auto;
    width: 19rem;
    max-height: 100%;
    outline: none !important;
    position: relative;
    .project-task-content-inner{
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .progress{
        border-radius: 0;
      }
      .project-task-add-btn{
        opacity: 0;
      }
      &:hover{
        .project-task-add-btn{
          opacity: 1;
        }
      }
      .project-task-box{
        padding: 20px 20px 20px 0;
        height: 700px;
        .project-task-card{
          background-color: $card-bg;
          box-shadow: $card-shadow;
          border-radius: 5px;
          margin-left: 2px;
          &.deadline{
            background-color: rgba($danger, 0.08);
            box-shadow: 0px 0px 6px 0px rgba($danger, 0.28);
            border: 0;
          }
          .project-task-date{
            svg{
              fill: $text-muted;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    } 
  }
}

@media (max-width: 1024px){
  .project-task-content{
    overflow-x: scroll;
  }
}