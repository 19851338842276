/*
Template Name: Unikit - Admin & Dashboard Template
Author: Mannatthemes
File: Horizontal-nav
*/



[data-layout="horizontal"] {
    display: inherit;   
    .topbar {
        background-color: $header-light-bg; 
        display: flex;
        border-bottom: 1px solid $border;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999;
        .brand {
            text-align: center;
            height: $header-height;
            width: 160px;
            background-color: $header-light-bg;
            border-bottom: none;
            box-shadow: $header-shadow;
            .logo {
                line-height: $header-height;
                .logo-sm {
                    height: 30px;                
                }
                .logo-lg {
                    height: 16px;
                    display: $brand-dark;
                    &.logo-light{
                      display: $brand-light;
                  }
                }  
            }
        }
        .navbar-custom{
            margin-left: 0;
            width: 100%;
            border-bottom: none;
            .navbar-custom-menu{
                min-height: $header-height;
                display: flex;
                align-items: center;                
            }
        }
    }
    
    .page-wrapper{
        margin: 0 auto;
        width: 1380px; 
        padding-top: $header-height; 
        .page-content-tab{
            margin-left: 0;
            width: 100%;
            margin-top: 0;
        }     
    }
    .app-search-topbar {
        form {
            position: relative;
            z-index: 1; 
        }
        button {
            position: absolute;
            width: 30px;
            height: 30px;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            text-align: center;
            background-color: transparent;
            color: $header-icon-color; 
            i{
                display: flex;
            }
            &:focus {
                outline: none; 
            }
        }
        input {
            width: 100%;
            height: 56px;
            border: none;
            font-size: 12px;
            border-radius: 4px;
            padding-left: 54px;
            padding-right: 15px;
            background-color: $dropdown-bg; 
            color: $body-color;
            &:focus {
                box-shadow: none;
                outline: none !important; 
            }
        }
    }
}


.navbar-custom-menu {
    .navigation-menu{
        .nav-item.active {
            a {
                color: $header-item-active-color;
                i{
                    color: $header-item-active-color;
                }
            }
            .dropdown-menu {
                li.active>a {
                    color: $header-item-active-color;
                }
            }
        }
        .nav-item {
            .nav-link {
                font-weight: 500;
                font-size: 14px;
                &:hover {
                    color: $header-item-hover-color;
                }
                &.active{
                    color: $header-item-active-color;
                    .menu-icon{
                        color: $header-item-active-color;
                    }
                }
            }
        }
    }  
    .dropdown {
        position: relative;
        .dropdown-toggle{
            &:after {
                display: block;
                content: "\f107";
                font-family: 'Line Awesome Free';
                font-weight: 900;
                color: $navbar-icon-color;
                border: none;
                font-size: 12px;
                margin-left: .255em;
                float: right;
            }
        }
        .nav-link{
            &.dropdown-toggle{
                &:after {
                    content: "\f107";
                    font-family: 'Line Awesome Free';
                    position: relative;
                    top: 2px;
                }
                &.arrow-none{
                    &:after{
                        display: none;
                    }
                }
            }
            &.active{
                &.dropdown-toggle{
                    &:after {
                        content: "\f107";
                        color: $header-item-active-color;
                    }
                } 
            }
        }
        .dropdown-menu {
            margin: 1.125rem 0;
            line-height: 1.2rem;
            min-width: 12rem;
            box-shadow: 0 3px 12px rgba($drop-shadow-color,.3);;
            border-radius: .5rem;
            top: 100% !important;
            .dropdown-item {
                position: relative;
                line-height: 26px;
                color: $header-item-color;
                &.active {
                    color: $primary;
                    text-decoration: none;
                    background-color: rgba($primary, .04);
                }
                &:last-child {
                    border-bottom: 0
                }
                &:focus {
                    background-color: rgba($primary, .04);
                    color: $primary;
                    border-radius: 0;
                    .dropdown-toggle{
                        &:after {
                            color: $primary;
                        }
                    }
                    &:hover {
                        color: $header-item-hover-color;
                        text-decoration: none;
                        background-color: rgba($primary, .04);
                        border-radius: 0
                    }
                    &:active {
                        color: $primary;
                        text-decoration: none;
                        background-color: rgba($primary, .04);
                        border-radius: .25rem
                    }
                }
                &:hover{
                    color: $header-item-hover-color;
                    text-decoration: none;
                    background-color: rgba($primary, .04);
                    border-radius: 0;
                    &.dropdown-toggle:after {
                        color: $header-item-hover-color;
                    }
                }
            }            
            
            .dropdown-submenu {
                a:after {
                    transform: rotate(-90deg);
                    right: 1rem
                }
                .dropdown-menu {
                    top: -18px !important;
                    left: 100%;
                    box-shadow: 0 3px 12px rgba($drop-shadow-color,.3);
                    min-width: 12rem;
                    border-radius: .5rem
                }
            }
            &.dropdown-menu-md {
                min-width: 17rem !important;
                border-radius: .5rem
            }
            &.dropdown-menu-xl {
                min-width: 24rem !important;
                border-radius: .5rem
            }
        }
    } 
}

.navbar-toggle {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:hover {
        background-color: transparent;
        span {
            background-color: $secondary;
        }
    }
    .lines {
        width: 20px;
        display: block;
        position: relative;
        padding-top: 24px;
        height: $header-height;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
    }
    span {
        height: 2px;
        width: 100%;
        background-color: $secondary;
        display: block;
        margin-bottom: 4px;
        transition: transform .5s ease;
    }
    &.open {
        span {
            position: absolute;
            &:first-child {
                top: 30px;
                transform: rotate(45deg);
                background-color: $secondary;
            }
            &:nth-child(2) {
                visibility: hidden;
            }
            &:last-child {
                width: 100%;
                top: 30px;
                transform: rotate(-45deg);
            }
        }
    }
}



.navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    >li {
        display: inline-block;
        position: relative;
        a {
            display: block;
            font-size: 13px;
            transition: all .1s ease;
            line-height: 18px;
            .menu-icon {
                display: inline-block;
                font-size: 20px;
                margin-right: 8px;
                transition: all .1s ease;
                vertical-align: text-bottom;
                color: $navbar-icon-color;
            }                
        }
    }
}

@media (max-width: 1499.98px) {  
    body[data-layout="horizontal"]{
        display: flex;        
        .page-wrapper{
            width: 100%;
            .page-content {
                min-height: calc(100vh - #{$header-height});
                margin-top: 0;
            } 
        }
    }
}
@media (max-width:1200px) {
    body[data-layout="horizontal"]{
        .navbar-custom-menu {
            .navigation-menu {
                .nav-item {
                    .nav-link {
                        padding: 5px 0;
                    }
                }
            }
        }
        .topbar{
            .brand {
                width: 60px !important;
                position: fixed;
                z-index: 1;
                .logo {                
                    .logo-lg {
                        display: none !important;
                    }  
                }
            } 
            .navigation-menu{
                margin-left: 60px;
            }
        } 
    }
}

@media (max-width: 1024px) {  
    body[data-layout="horizontal"]{
        display: flex;
        .page-wrapper{
            width: 100%;
        }
    }
}



@media (min-width: 992px) {
    
    .topbar {
        .navigation-menu{           
            >li{
                >a {
                    display: flex;
                    align-items: center;
                    color: $header-item-color;   
                    min-height: $header-height;  
                    font-weight: 500;   
                    span{
                        vertical-align: middle;
                        padding: 4px;
                        border-radius: 4px; 
                        menu-icon{
                            color: $gray-500;
                            font-size: 20px;
                        }
                    }                              
                }
                &:hover a {
                    color: $header-item-hover-color; 
                    i{
                        color: $header-item-hover-color; 
                    }
                }                
                &.active{
                    border-bottom: 1px solid $primary;
                }               
            }            
        }
        .navbar-toggle {
            display: none;
        }
        #navigation {
            display: block;        
        }
    } 
    .animate {
        animation-duration: 0.3s;
        animation-fill-mode: both;
      }
    .navbar-custom-menu{
        .dropdown {
            .dropdown-submenu:hover{
                > .dropdown-menu{
                    display: block;
                }
            }
        }
    }    
}


@media (max-width: 991px) {
    body[data-layout="horizontal"]{
        .page-wrapper{
            width: 100%;
        }
    }
    
    .topbar {
        .navigation-menu {
            float: none;
            max-height: 400px;
            text-align: left;
            width: 100%; 
            margin-left: 0 !important;           
            >li {
                display: block;
                >a {
                    color: $mobile-menu-item-color;
                    padding: 15px;
                    font-size: 13px;
                    
                    &:after {
                        position: absolute;
                        right: 15px;
                    }
                    &:hover{
                        color: $primary;
                    }                    
                }
            }
        }
        .navbar-header {
            float: left;
        }
    }


    #navigation {
        position: absolute;
        top: $header-height;
        left: 0;
        right: 0;
        display: none;
        height: auto;
        max-height: 500px;
        padding: 8px;
        overflow: auto;
        background-color: $card-bg;
        z-index: 1000;
        box-shadow: 0px 1px 3px 1.25px rgba($gray-500, 0.3);
    }
    #navigation.open {
        display: block;
        overflow-y: auto;
    }
    .navbar-custom-menu{
        min-height: 0;
    }
    .topbar {
        .navigation-menu {
            margin-left: 0;
            .dropdown-menu{
                margin: 0;
                .dropdown-submenu {
                    .dropdown-menu {
                        box-shadow: none;
                        margin: 0;
                    }
                }
            } 
            .nav-item {
                .nav-link {
                    padding: .5rem 0!important;
                    border-bottom: 1px solid $border;
                }
                .dropdown-menu{
                    position: static !important;
                    margin: 0;
                    transform: none !important;
                    .dropdown-submenu {
                        .dropdown-menu {
                            box-shadow: none;
                            margin: 0;
                        }
                    }
                }
            }  
        }           
    }
    .dark-topbar {
        .topbar {
            .navigation-menu>li>a{
                color: $header-item-color !important;
            }
        }
    }
}



@media (min-width: 768px) {
    .navbar-toggle {
        display: block;
    } 
}
@media (max-width: 1129.98px) {
    .topbar {
         .nav-user{
             .user-name{
                 display: none;
             }
         }
    }
}

@media (max-width: 767.98px){
    
    .topbar{
        .brand{
            margin-right: 0;
            .logo{
                .logo-sm {
                    height: 32px;
                }
                .logo-lg{
                    display: none;
                }
            }
        }  
    }
    .page-content {
        width: 100%;
        margin-top: 0;
        min-height: 100vh;
        padding: 0 0 60px 0;
    }   
    .hide-phone{
        display: none;
    }  
}

@media (max-width: 767px) {
    .page-title-box {
        .breadcrumb{
            display: none;
        }
    }      
}

@media (max-width: 620px) {
  .logo-large {
      display: none;
  }
  .logo-small {
      display: inline-block !important;
  }
}


  
@keyframes slideIn {
0% {
    transform: translateY(1rem);
    opacity: 0;
}
100% {
    transform:translateY(0rem);
    opacity: 1;
}
0% {
    transform: translateY(1rem);
    opacity: 0;
}
}

@-webkit-keyframes slideIn {
0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
}
100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
}
0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
}
}

.slideIn {
-webkit-animation-name: slideIn;
animation-name: slideIn;
}

body[data-layout="horizontal"]{
    &.dark-topbar{
        .navbar-custom,
        .topbar{
            background-color: $header-dark-bg;
            .brand{
                background-color: $header-dark-bg;
                .logo {
                    .logo-lg {
                        display: $brand-light;
                        &.logo-light{
                          display: $brand-dark;
                      }
                    }  
                }
            }
        }
        .navigation-menu>li a .menu-icon{
            color: $navbar-icon-dark-color;
        }
        .topbar .navigation-menu>li>a{
            color: $header-item-dark-color;
        }
        .navbar-custom-menu {
            .dropdown {
                position: relative;
                .dropdown-toggle{
                    &:after {
                        color: $navbar-icon-dark-color;
                    }
                }
            }
            .navigation-menu {                
                .nav-item {
                    .nav-link{
                        &.active,
                        &:hover{
                            color: $header-item-dark-hover-color;
                        }
                    }
                }
            }
        }
        .navbar-custom .nav-link.nav-icon i{
            color: $header-icon-dark-color;
        }
        .navbar-custom .nav-link.nav-icon{
            background-color: $header-icon-dark-bg;
        }
        .navbar-custom .nav-link{
            color: $header-item-dark-color;
        }
    }
}

